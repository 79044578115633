import { IClientexdel, iniClientexdel } from "@/entities";

export interface IPuntoservicio {
  clientexdel: IClientexdel;
  codPuntoservicio: string;
  desPuntoservicio: string;
  id: number;
}

export const iniPuntoservicio: IPuntoservicio = {
  clientexdel: iniClientexdel,
  codPuntoservicio: "",
  desPuntoservicio: "",
  id: 0,
};
