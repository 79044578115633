
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { iniVideoParams, IVideoParams } from "@/services";
import { ICliente, IClientexdel, IDelegacion, IParametro, IPuntoservicio } from "@/entities";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";

@Component({
  mixins: [VueMethods],
})
export class VideosUploadForm extends Vue {
  @Prop({ required: true }) value!: IVideoParams;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Prop({ required: true }) optionTiposNovedad!: IParametro[];
  @Getter("getLocale") locale!: Locale;
  @Getter("getClientesAll") getClientesAll!: ICliente[];
  @Getter("getClienteSelected") clienteSelected: ICliente | undefined;
  public delegations: IDelegacion[] = [];
  public puntos: IPuntoservicio[] = [];
  validado = false;
  valueLocal = iniVideoParams;
  optionsClientes: ICliente[] = [];
  file: File | null = null;
  inicioSeleccion = new Date(1900, 0, 1);
  private cliente: ICliente | undefined = undefined;
  private static readonly SORTBY_DELEGATIONS: string = "desDelegacion";
  private static readonly SORTBY_PUNTOS: string = "desPuntoservicio";

  mounted(): void {
    this.optionsClientes = this.getClientesAll;
    if (this.clienteSelected) {
      this.valueLocal.clienteId = this.clienteSelected?.id;
      this.onChangeClient();
    }
  }

  async onChangeClient(): Promise<void> {
    if (this.valueLocal.clienteId) {
      let aux = await this.$services.clientes._fetchCliente(Number(this.valueLocal.clienteId));
      if (aux) {
        this.cliente = aux;
        this.limpiar();
        this.getDelegations();
      }
    }
  }

  limpiar(): void {
    this.valueLocal.delegacionId = null;
    this.valueLocal.puntoServicioId = null;
    this.delegations = [];
    this.puntos = [];
  }

  getDelegations(): void {
    const d: IDelegacion[] = [];
    if (this.cliente && this.cliente?.clientexdels) {
      this.cliente?.clientexdels.forEach((clientexdel) => {
        if (clientexdel?.delegacion && clientexdel?.active) {
          d.push(clientexdel.delegacion);
        }
      });
      this.delegations = d.sort((a, b) =>
        this.sortAscending(a, b, VideosUploadForm.SORTBY_DELEGATIONS)
      );
    }
  }

  getPuntos(): void {
    this.valueLocal.puntoServicioId = null;
    this.puntos = [];
    if (this.cliente != undefined) {
      let cxds = this.cliente.clientexdels;
      if (this.valueLocal.delegacionId) {
        cxds = cxds?.filter(
          (cxd: IClientexdel) => this.valueLocal.delegacionId == cxd.delegacion?.id
        );
      }
      cxds?.forEach((cxd) =>
        this.puntos.push(
          ...(cxd.puntosservicio.sort((a, b) =>
            this.sortAscending(a, b, VideosUploadForm.SORTBY_PUNTOS)
          ) || [])
        )
      );
    }
  }

  accept(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action, file: this.file });
  }
  cancel(): void {
    this.$emit("cancel", { data: this.valueLocal, action: "cancel" });
  }
}

export default VideosUploadForm;
