
import { Component, Prop, Vue } from "vue-property-decorator";
import QuickFilter from "../common/QuickFilter.vue";
import SaldosTable from "./ColapsableTodosTable.vue";
import SaldosTodosFiltersForm from "./SaldosTodosFiltersForm.vue";
import { Getter } from "vuex-class";
import { ISaldosQuickFilter } from "../../types";
import { mapGetters } from "vuex";
import SaldosModal from "../common/SaldosModal.vue";
import { IColumnaResponse, IMovimientoParams } from "@/services";
import { Dictionary } from "vue-router/types/router";
import { ICliente, ISaldo } from "@/entities";
import VueMethods from "@/vue-methods";
import { Locale } from "@/store";
import { SweetAlertResult } from "sweetalert2";
import { FlowReportTypeData } from "@/entities/centros-efectivo-backend/reporte.types";

@Component({
  name: "saldos",
  components: {
    SaldosModal,
    QuickFilter,
    SaldosTable,
    SaldosTodosFiltersForm,
  },
  mixins: [VueMethods],
  computed: {
    ...mapGetters(["getClienteXDivisa"]),
  },
})
export class SaldosTodos extends Vue {
  @Getter("getClienteSelected") clienteSelected: ICliente | undefined;
  @Getter("getSaldosPageQuickFilter") getSaldosPageQuickFilter!: ISaldosQuickFilter;
  @Getter("getLocale") locale!: Locale;

  @Prop({ required: true, default: {} }) saldosConfig!: IColumnaResponse;
  @Prop({ required: true, default: {} }) entradasConfig!: IColumnaResponse;
  @Prop({ required: true, default: {} }) salidasConfig!: IColumnaResponse;
  @Prop({ required: true, default: {} }) detalleSaldosConfig!: IColumnaResponse;
  @Prop({ required: false, default: false }) isBCR!: boolean;
  @Prop() public params!: IMovimientoParams;

  mostrarFiltros = false;
  saldo: ISaldo | null = null;
  idCliente: number | null = null;

  filtersSaldos: any = {
    fecCertificadoInicio: this.getStartLocalDate(new Date().toISOString()),
    fecCertificadoFin: this.getEndLocalDate(new Date().toISOString()),
    delegationsSelected: [],
  };

  flowReportTypes: FlowReportTypeData[] = [];

  get quickFilter(): ISaldosQuickFilter {
    return this.getSaldosPageQuickFilter;
  }

  get hasDivisasFilter(): boolean {
    return this.quickFilter.divisa.length > 0;
  }

  get hasClientFilterButNoDivisasFilter(): boolean {
    if (this.params?.pkCliente) {
      return true;
    }

    return this.hasDivisasFilter;
  }

  beforeMount(): void {
    this.params.pkCliente = this.clienteSelected?.id ?? this.params.pkCliente;
    this.params.idioma = this.locale;

    const fechaInicio =
      this.params.fecCertificadoInicio ??
      this.params.fecMovimientoInicio ??
      this.params.fecEntregaInicio ??
      this.params.fecContableInicio ??
      this.params.fecServicioInicio;

    const fechaFin =
      this.params.fecCertificadoFin ??
      this.params.fecMovimientoFin ??
      this.params.fecEntregaFin ??
      this.params.fecContableFin ??
      this.params.fecServicioFin;

    this.resetFechas();

    this.filtersSaldos = {
      fecCertificadoInicio: fechaInicio
        ? this.getStartLocalDate(fechaInicio)
        : this.getStartLocalDate(new Date().toISOString()),
      fecCertificadoFin: fechaFin
        ? this.getEndLocalDate(fechaFin)
        : this.getEndLocalDate(new Date().toISOString()),
      delegationsSelected: this.params.pkDelegacion ?? [],
    };

    this.params.fecCertificadoInicio = this.filtersSaldos.fecCertificadoInicio;
    this.params.fecCertificadoFin = this.filtersSaldos.fecCertificadoFin;
  }

  async mounted(): Promise<void> {
    this.$router
      .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
      .catch(() => {
        // Do nothing
      });

    this.flowReportTypes = (await this.$services.reporte.getFlowReportTypes()) ?? [];
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersSaldos).forEach((key: string) => {
      if (this.filtersSaldos[key] != "" && this.filtersSaldos[key] != null) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros ? `(${numFiltros}) ${this.$t("label.filtros")}` : this.$t("label.filtros");
  }

  resetFilters(delegaciones: []): void {
    this.filtersSaldos.delegationsSelected = delegaciones;
  }

  resetFechas(): void {
    this.params.fecContableInicio = undefined;
    this.params.fecContableFin = undefined;
    this.params.fecEntregaInicio = undefined;
    this.params.fecEntregaFin = undefined;
    this.params.fecServicioInicio = undefined;
    this.params.fecServicioFin = undefined;
    this.params.fecMovimientoInicio = undefined;
    this.params.fecMovimientoFin = undefined;
  }

  action(action: { action: string; data?: any }): void {
    if (action.action === "filtrar" || action.action === "clean") {
      this.params.fecCertificadoInicio = this.getStartLocalDate(
        this.filtersSaldos.fecCertificadoInicio
      );
      this.params.fecCertificadoFin = this.getEndLocalDate(this.filtersSaldos.fecCertificadoFin);
      this.params.pkDelegacion = this.filtersSaldos.delegationsSelected ?? undefined;
      this.$router
        .replace({
          path: this.$route.path,
          query: { ...this.params } as Dictionary<any>,
        })
        .catch(() => {
          // Do nothing
        });
      this.mostrarFiltros = false;
    }
  }

  async downloadSaldosTodos(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.saldos._downloadSaldosBCR(this.params);
      } else {
        await this.$services.saldos._downloadSaldos(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async downloadFull(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.saldos._downloadFullBCR(this.params);
      } else {
        await this.$services.saldos._downloadFull(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async downloadSaldosPDF(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.saldos._downloadSaldosPDFBCR(this.params);
      } else {
        await this.$services.saldos._downloadSaldosPDF(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async downloadSaldosPDFWithoutDenominations(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.saldos._downloadSaldosPDFWithoutDenominationsBCR(this.params);
      } else {
        await this.$services.saldos._downloadSaldosPDFWithoutDenominations(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async downloadSaldosExcelWithoutDenominations(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.saldos._downloadSaldosExcelWithoutDenominationsBCR(this.params);
      } else {
        await this.$services.saldos._downloadSaldosExcelWithoutDenominations(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async confirmDescarga(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.confirmarDescarga.title"),
      text: this.$t("label.confirmarDescarga.message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes"),
      cancelButtonText: this.$t("label.no"),
    });
  }

  async onGenerateOrdersReport(type: string): Promise<void> {
    if (type === "EXCEL") {
      this.downloadSaldosTodos();
    } else if (type === "FULL") {
      this.downloadFull();
    } else if (type === "PDF") {
      this.downloadSaldosPDF();
    } else if (type === "PDF_WITHOUT_DENOMINATIONS") {
      this.downloadSaldosPDFWithoutDenominations();
    } else if (type === "EXCEL_WITHOUT_DENOMINATIONS") {
      this.downloadSaldosExcelWithoutDenominations();
    }
  }
}

export default SaldosTodos;
