import { IPaged, IResponse } from "@/services";
import { IVideo } from "@/entities/centros-efectivo-backend/video.types";
import { IParametro } from "@/entities";

export interface IVideosService {
  getVideos(params: IVideoFilter): Promise<IVideoResponse | undefined>;
  getVideo(idVideo: number): Promise<IVideo | undefined>;
  getEstadosVideos(): Promise<IParametro[] | undefined>;
  getTiposNovedad(): Promise<IParametro[] | undefined>;
  save(
    videoParams: IVideoParams,
    file: File,
    progress: { total: number; parcial: number }
  ): Promise<IVideo | undefined>;
  saveAcceptData(usuarioxVideoParams: IUsuarioxVideoParams): Promise<IVideo | undefined>;
  saveVisionado(usuarioxVideoParams: IUsuarioxVideoParams): Promise<IVideo | undefined>;
  downloadInforme(pkVideo: number, idioma: string): Promise<void>;
  removeVideo(pkVideo: number): Promise<void>;
}

export interface IVideoResponse extends IResponse<IVideo> {}

export interface IVideoFilter extends IPaged {
  [key: string]: any;
  ciudad?: string;
  sucursal?: string;
  clienteId?: number;
  fechaNovedad?: string;
  fechaSubida?: string;
  pkCliente?: number;
  pkDelegaciones?: number[];
  pkPuntosServ?: number[];
  cedulasCiudadania?: string;
  nombreRevisores?: string;
  nombreArchivo?: string;
  observaciones?: string;
  estado?: string[];
  tipoNovedad?: string[];
  delegationsSelected: number[];
  puntosSelected: number[];
}

export interface IVideoParams {
  id?: number;
  ciudad?: string;
  sucursal?: string;
  clienteId?: number | null;
  fecha?: string;
  fechaNovedad?: string;
  cedulasCiudadania?: string;
  nombreRevisores?: string;
  nombreArchivo?: string;
  observaciones?: string;
  estado?: IParametro;
  tipoNovedadId?: number | null;
  importe?: number | null;
  delegacionId?: number | null;
  puntoServicioId?: number | null;
}

export const iniVideoParams: IVideoParams = {
  fecha: new Date().toISOString(),
  clienteId: null,
  ciudad: "",
  sucursal: "",
  fechaNovedad: new Date().toISOString(),
  cedulasCiudadania: "",
  nombreRevisores: "",
  tipoNovedadId: null,
  observaciones: "",
  delegacionId: null,
  puntoServicioId: null,
};

export interface IUsuarioxVideoParams {
  id?: number;
  aceptaDatos: boolean;
  aceptaNovedad?: boolean;
  observaciones?: string;
  timezone: string;
}

export interface IVideosServiceMock {
  getVideos: jest.Mock<Promise<IVideoResponse | undefined>>;
  getVideo: jest.Mock<Promise<IVideo | undefined>>;
  getEstadosVideos: jest.Mock<Promise<IParametro[] | undefined>>;
  getTiposNovedad: jest.Mock<Promise<IParametro[] | undefined>>;
  save: jest.Mock<Promise<IVideo | undefined>>;
  saveAcceptData: jest.Mock<Promise<IVideo | undefined>>;
  saveVisionado: jest.Mock<Promise<IVideo | undefined>>;
  downloadInforme: jest.Mock<Promise<void>>;
  removeVideo: jest.Mock<Promise<void>>;
}
