
import { Component, Vue } from "vue-property-decorator";
import PanelWidgets from "@/components/common/PanelWidgets.vue";
import SaldosTodos from "./components/todos/SaldosTodos.vue";
import SaldosEntradas from "./components/entradas/SaldosEntradas.vue";
import SaldosSalidas from "./components/salidas/SaldosSalidas.vue";
import { Action } from "vuex-class";
import { IColumnaResponse, IMovimientoParams } from "@/services";
import VueMethods from "@/vue-methods";
import { IOptionBarItem } from "@/components/look";

@Component({
  components: {
    PanelWidgets,
    SaldosTodos,
    SaldosEntradas,
    SaldosSalidas,
  },
  mixins: [VueMethods],
})
export class SaldosPage extends Vue {
  @Action("fetchColumna") fetchColumna!: (
    codTabla: string
  ) => Promise<IColumnaResponse | undefined>;

  saldosConfig: IColumnaResponse | null = null;
  entradasConfig: IColumnaResponse | null = null;
  salidasConfig: IColumnaResponse | null = null;
  detalleSaldosConfig: IColumnaResponse | null = null;

  queryParams = this.$route.query;

  params: IMovimientoParams = {
    canal: this.queryParams.canal?.toString(),
    codTipoEntrada: this.queryParams.codTipoEntrada?.toString(),
    comentarioAuto: this.queryParams.comentarioAuto?.toString(),
    comentarioManual: this.queryParams.comentarioManual?.toString(),
    diferencia: this.queryParams.diferencia
      ? this.queryParams.diferencia.toString() === "true"
      : undefined,
    fecCertificadoInicio:
      this.getStartLocalDate(this.queryParams.fecCertificadoInicio?.toString()) ||
      this.getStartLocalDate(new Date().toISOString()),
    fecCertificadoFin:
      this.getEndLocalDate(this.queryParams.fecCertificadoFin?.toString()) ||
      this.getEndLocalDate(new Date().toISOString()),
    fecAlbaranInicio: this.getStartLocalDate(this.queryParams.fecAlbaranInicio?.toString()),
    fecAlbaranFin: this.getEndLocalDate(this.queryParams.fecAlbaranFin?.toString()),
    fecContableInicio:
      this.getStartLocalDate(this.queryParams.fecContableInicio?.toString()) ||
      this.getStartLocalDate(new Date().toISOString()),
    fecContableFin:
      this.getEndLocalDate(this.queryParams.fecContableFin?.toString()) ||
      this.getEndLocalDate(new Date().toISOString()),
    fecMovimientoInicio: this.getStartLocalDate(this.queryParams.fecMovimientoInicio?.toString()),
    fecMovimientoFin: this.getEndLocalDate(this.queryParams.fecMovimientoFin?.toString()),
    fecServicioInicio: this.getStartLocalDate(this.queryParams.fecServicioInicio?.toString()),
    fecServicioFin: this.getEndLocalDate(this.queryParams.fecServicioFin?.toString()),
    fecEntregaInicio: this.getStartLocalDate(this.queryParams.fecEntregaInicio?.toString()),
    fecEntregaFin: this.getEndLocalDate(this.queryParams.fecEntregaFin?.toString()),
    fkPuntoServ:
      this.queryParams.fkPuntoServ
        ?.toString()
        .split(",")
        .map((pk) => Number(pk)) || [],
    numeroAlbaran: this.queryParams.numeroAlbaran?.toString(),
    numeroCuenta: this.queryParams.numeroCuenta?.toString(),
    numeroPrecinto: this.queryParams.numeroPrecinto?.toString(),
    pkCliente: this.queryParams.pkCliente ? Number(this.queryParams.pkCliente) : undefined,
    pkDelegacion: this.queryParams.pkDelegacion
      ?.toString()
      .split(",")
      .map((pk) => Number(pk)),
    pkDivisa: this.queryParams.pkDivisa ? Number(this.queryParams.pkDivisa) : undefined,
    pkSaldo: this.queryParams.pkSaldo ? Number(this.queryParams.pkSaldo) : undefined,
  };

  async created(): Promise<void> {
    const responses = await Promise.all([
      this.fetchColumna("saldos"),
      this.fetchColumna("entradas"),
      this.fetchColumna("salidas"),
      this.fetchColumna("detalle_saldos"),
    ]);

    this.saldosConfig = responses[0] || {
      codTabla: "saldos",
      codColumna: "",
    };
    this.entradasConfig = responses[1] || {
      codTabla: "entradas",
      codColumna: "",
    };
    this.salidasConfig = responses[2] || {
      codTabla: "salidas",
      codColumna: "",
    };
    this.detalleSaldosConfig = responses[3] || {
      codTabla: "detalle_saldos",
      codColumna: "",
    };
  }

  get optionSelected(): string {
    return this.$route.name || "todos";
  }

  set optionSelected(option: string) {
    this.$router.push({ path: `/saldos/${option}` });
  }

  get options(): IOptionBarItem[] {
    return [
      {
        text: this.$t("label.todos"),
        value: "todos",
        hidden: !this.$ability.can("read", "SaldosTodos"),
      },
      {
        text: this.$t("label.entradas"),
        value: "entradas",
        hidden: !this.$ability.can("read", "SaldosEntradas"),
      },
      {
        text: this.$t("label.salidas"),
        value: "salidas",
        hidden: !this.$ability.can("read", "SaldosSalidas"),
      },
    ];
  }
}
export default SaldosPage;
