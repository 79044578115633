
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import {
  ICliente,
  IClientexdel,
  IDelegacion,
  IPuntoservicio,
  IUsuario,
  KeyValuePair,
} from "@/entities";
import VueMethods from "@/vue-methods";
import { MAX_RANGE_MONTH_DATE } from "@/constans";

@Component({
  mixins: [VueMethods],
})
export class SaldosEntradasFiltersForm extends Vue {
  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.$set(
      this.valueLocal,
      "fechaInicio",
      this.valueLocal.fechaInicio
        ? new Date(this.valueLocal.fechaInicio!)
        : this.getStartLocalDate(new Date().toISOString())
    );
    this.$set(
      this.valueLocal,
      "fechaFin",
      this.valueLocal.fechaFin
        ? new Date(this.valueLocal.fechaFin!)
        : this.getEndLocalDate(new Date().toISOString())
    );
  }
  tiposFecha: KeyValuePair[] = [
    {
      key: "CONTABLE",
      value: this.$t("label.fecContable"),
    },
    {
      key: "SERVICIO",
      value: this.$t("label.fecServicio"),
    },
    {
      key: "MOVIMIENTO",
      value: this.$t("label.fecMovimiento"),
    },
  ];
  valueLocal: any = {
    tipoFechaSelected: this.tiposFecha[0].key,
    fechaInicio: this.getStartLocalDate(new Date().toISOString()),
    fechaFin: this.getEndLocalDate(new Date().toISOString()),
    delegationsSelected: [],
    puntosSelected: [],
  };
  initialValueLocal: any = {
    tipoFechaSelected: this.tiposFecha[0].key,
    fechaInicio: this.getStartLocalDate(new Date().toISOString()),
    fechaFin: this.getEndLocalDate(new Date().toISOString()),
    delegationsSelected: [],
    puntosSelected: [],
  };
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getLocale") locale!: Locale;
  @Getter("getLoggedUser") getLoggedUser!: IUsuario;
  @Getter("getClienteSelected") clienteSelected: ICliente | undefined;
  public delegations: IDelegacion[] = [];
  public puntos: IPuntoservicio[] = [];
  validado = false;
  maxRange = MAX_RANGE_MONTH_DATE;

  mounted(): void {
    this.getDelegaciones();
    this.getPuntos();
  }
  private cliente: ICliente | undefined = undefined;
  private static readonly SORTBY_DELEGATIONS: string = "desDelegacion";

  async getDelegaciones(): Promise<void> {
    this.cliente = this.clienteSelected;
    this.cliente?.clientexdels.forEach((clientexdel) => {
      if (clientexdel.active) this.delegations.push(clientexdel.delegacion);
    });
    this.delegations.sort((a, b) =>
      this.sortAscending(a, b, SaldosEntradasFiltersForm.SORTBY_DELEGATIONS)
    );
  }
  async getPuntos(): Promise<void> {
    if (this.cliente != undefined) {
      let cxds = this.cliente.clientexdels;
      if (this.getLoggedUser.nivel == 4) {
        const puntosPermitidos = this.getLoggedUser.permisos.map((punto) => punto.puntoservicio.id);
        cxds.forEach((clientexdel) => {
          clientexdel.puntosservicio = clientexdel.puntosservicio.filter((punto) =>
            puntosPermitidos.includes(punto.id)
          );
        });
      }
      if (this.valueLocal.delegationsSelected?.length) {
        cxds = cxds?.filter((cxd: IClientexdel) =>
          this.valueLocal.delegationsSelected.includes(cxd.delegacion?.id)
        );
      }
      cxds?.forEach((cxd) => this.puntos.push(...(cxd.puntosservicio || [])));
    }
  }
  limpiar(): void {
    this.valueLocal.tipoFechaSelected = this.tiposFecha[0].key;
    this.valueLocal.fechaInicio = this.getStartLocalDate(new Date().toISOString());
    this.valueLocal.fechaFin = this.getEndLocalDate(new Date().toISOString());
    this.valueLocal.delegationsSelected = [];
    this.valueLocal.puntosSelected = [];
    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }
}
export default SaldosEntradasFiltersForm;
