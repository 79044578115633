
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import { iniVideo, IVideo } from "@/entities/centros-efectivo-backend/video.types";
import VueFilters from "@/vue-filters";
import { iniUsuarioxVideo } from "@/entities/centros-efectivo-backend/usuario-x-video.types";
import VideosMaximizedForm from "@/pages/videos/components/VideosMaximizedForm.vue";

@Component({
  mixins: [VueFilters],
  components: {
    VideosMaximizedForm,
  },
})
export class VideosPlayForm extends Vue {
  @Prop({ required: true }) value!: IVideo;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) {
      this.valueLocal = JSON.parse(JSON.stringify(this.value));
      if (this.valueLocal.listUsuarios?.length) {
        this.visionadoLocal = JSON.parse(JSON.stringify(this.valueLocal.listUsuarios[0]));
        this.aceptaDatosReadonly = true;
        if (this.valueLocal.listUsuarios[0].aceptaNovedad === null) {
          this.visionadoLocal.aceptaNovedad = false;
          this.aceptaNovedadReadonly = false;
        } else {
          this.aceptaNovedadReadonly = true;
        }
      } else {
        this.$bvToast.toast(this.$t("label.videos.prosegur.confidencial"), {
          title: this.$t("label.confidencial"),
          variant: "warning",
          solid: true,
          toaster: "b-toaster-top25-center",
          noAutoHide: true,
        });
        this.visionadoLocal = JSON.parse(JSON.stringify(iniUsuarioxVideo));
        this.visionadoLocal.fkVideo = this.valueLocal.id;
        this.aceptaDatosReadonly = false;
        this.aceptaNovedadReadonly = false;
      }
    }
  }

  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getLocale") locale!: Locale;
  validado = false;
  isConfirm = false;
  aceptaDatosReadonly = false;
  aceptaNovedadReadonly = false;
  valueLocal: IVideo = JSON.parse(JSON.stringify(iniVideo));
  visionadoLocal = JSON.parse(JSON.stringify(iniUsuarioxVideo));
  isColapsableDatosNovedad = false;
  isColapsableVideo = false;
  verModal = false;

  validarColapsable(value: boolean, componentName: string): void {
    if ("datos-novedad" === componentName) {
      this.isColapsableDatosNovedad = value;
      this.isColapsableVideo = this.isColapsableVideo ? !this.isColapsableDatosNovedad : false;
    } else if ("video" === componentName) {
      this.isColapsableVideo = value;
      this.isColapsableDatosNovedad = this.isColapsableDatosNovedad
        ? !this.isColapsableVideo
        : false;
    }
  }

  verVideoMaximizado(): void {
    this.verModal = true;
  }

  get showDetailsVideo(): boolean {
    return (
      this.aceptaDatosReadonly &&
      this.visionadoLocal.aceptaDatos &&
      this.valueLocal.urlVideo.indexOf("/") > 0
    );
  }

  get controls(): string {
    if (this.valueLocal.cliente.pais.descargarVideos) {
      return "nofullscreen noremoteplayback";
    }
    return "nodownload nofullscreen noremoteplayback";
  }

  get valuesVideo(): any {
    return {
      fecha: this.filterDateTime(this.valueLocal.fecha),
      cliente: this.valueLocal.cliente.desCliente,
      tipoNovedad: this.valueLocal.tipoNovedad
        ? this.$t(`label.tipoNovedad.${this.valueLocal.tipoNovedad.codParametro?.toLowerCase()}`)
        : undefined,
      importe: this.valueLocal.importe ? this.filterNumber(this.valueLocal.importe) : undefined,
      ciudad: this.valueLocal.ciudad,
      sucursal: this.valueLocal.sucursal,
      puntoServicio: this.valueLocal.puntoServicio.desPuntoservicio,
      delegacion: this.valueLocal.delegacion.desDelegacion,
      fechaNovedad: this.filterDateTime(this.valueLocal.fechaNovedad),
      nombreRevisores: this.valueLocal.nombreRevisores,
      cedulasCiudadania: this.valueLocal.cedulasCiudadania,
      observaciones: this.valueLocal.observaciones,
    };
  }

  fieldsVideo = {
    fecha: { title: this.$t("label.fecha"), width: "25%", emptyShow: false },
    cliente: { title: this.$t("label.cliente"), width: "75%", emptyShow: false },
    fechaNovedad: { title: this.$t("label.fechaNovedad"), width: "25%", emptyShow: false },
    tipoNovedad: { title: this.$t("label.tipoNovedad"), width: "25%", emptyShow: false },
    importe: { title: this.$t("label.importe"), width: "25%", emptyShow: false },
    ciudad: { title: this.$t("label.ciudad"), width: "25%", emptyShow: false },
    sucursal: { title: this.$t("label.sucursal"), width: "50%", emptyShow: false },
    delegacion: { title: this.$t("label.delegacion"), width: "25%", emptyShow: false },
    puntoServicio: { title: this.$t("label.puntoServicio"), width: "25%", emptyShow: false },
    nombreRevisores: { title: this.$t("label.nombreRevisor"), width: "25%", emptyShow: false },
    cedulasCiudadania: {
      title: this.$t("label.cedulasCiudadania"),
      width: "75%",
      emptyShow: false,
    },
    observaciones: { title: this.$t("label.observaciones"), width: "100%", emptyShow: false },
  };

  action(action: string): void {
    if (!this.valueLocal.listUsuarios?.length) {
      this.valueLocal.listUsuarios?.push(this.visionadoLocal);
    } else {
      this.valueLocal.listUsuarios[0] = this.visionadoLocal;
    }

    if (action === "aceptarDatos") {
      this.aceptaDatosReadonly = true;
      this.$emit("action", { data: this.valueLocal, action: action });
    } else if (action === "aceptar") {
      if (!this.aceptaDatosReadonly) {
        this.isConfirm = true;
      } else {
        this.$emit("action", { data: this.valueLocal, action: action });
      }
    } else if (action === "aceptarConfirm") {
      this.$emit("action", { data: this.valueLocal, action: "aceptar" });
      this.aceptaDatosReadonly = true;
      this.isConfirm = false;
    }
  }
}

export default VideosPlayForm;
