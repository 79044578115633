
import VueFilters from "@/vue-filters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import { IConfColumnaPais, IDetallesSaldo, IField, ISaldo } from "@/entities";
import { IColumnaResponse } from "@/services";

@Component({
  name: "detalle-saldo",
  mixins: [VueFilters],
})
export default class DetalleSaldo extends Vue {
  @Prop() public saldo!: ISaldo;
  @Prop({ required: true }) columnsConfig!: IColumnaResponse;

  @Action("fetchSaldoDetalle") fetchDetalle!: (id: number) => Promise<IDetallesSaldo[] | undefined>;

  @Action("setLoading") setLoading!: (loading: boolean) => void;

  detalles: IDetallesSaldo[] | null = null;

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    let columnas: IField[] = [];
    this.columnsConfig.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna).toUpperCase(),
        sortable: true,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        aux: false,
        code: column.codColumna,
        formatter: (value: any): string => {
          if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          }
          return value;
        },
      });
    });
    return columnas;
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }

  get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }

  constructor() {
    super();
  }
  mounted(): void {
    this.fetch();
  }
  async fetch(): Promise<void> {
    const aux = await this.fetchDetalle(this.saldo.id);
    if (aux) {
      this.detalles = aux;
    }
  }
}
