import { APPLICATION_JSON } from "@/constans";
import { EventBus } from "@/event-bus";
import Vue from "vue";
import { handleResponse } from "@/services/handlers";
import {
  BACKEND_BASE_URL,
  ISaldoActualParams,
  ISaldoData,
  ISaldoParams,
  ISaldosService,
  serialize,
} from "@/services";
import { IResponseError } from "@/entities/common/common.types";
import { IDetallesSaldo, ISaldo } from "@/entities";

export class SaldosService implements ISaldosService {
  async _fetch(params: ISaldoParams): Promise<ISaldoData | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ISaldoData, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/saldo`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchBCR(params: ISaldoParams): Promise<ISaldoData | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ISaldoData, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/saldo/BCR`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchDetalle(id: number): Promise<IDetallesSaldo[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IDetallesSaldo[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/saldo/` + id, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchSaldoActual(params: ISaldoActualParams): Promise<ISaldo | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ISaldo, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/saldo/actual`, { headers, params })
    );
    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchSaldosActuales(params: ISaldoActualParams): Promise<ISaldo[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ISaldo[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/saldo/actuales`, { headers, params })
    );
    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _downloadSaldos(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelSaldos`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }

  async _downloadSaldosBCR(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelSaldos/BCR`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }

  async _downloadFull(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelFull`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }

  async _downloadFullBCR(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelFull/BCR`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }

  async _downloadSaldosPDF(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFSaldos`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }

  async _downloadSaldosPDFBCR(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFSaldos/BCR`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }

  async _downloadSaldosPDFWithoutDenominations(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFSaldosWithoutDenominations`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }

  async _downloadSaldosPDFWithoutDenominationsBCR(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFSaldosWithoutDenominations/BCR`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }

  async _downloadSaldosExcelWithoutDenominations(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelSaldosWithoutDenominations`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }

  async _downloadSaldosExcelWithoutDenominationsBCR(params: ISaldoParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelSaldosWithoutDenominations/BCR`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldos-error", error.response?.data);
    }
  }
}
