import {
  ICliente,
  IDelegacion,
  iniCliente,
  iniDelegacion,
  IPuntoservicio,
  IUsuarioCrear,
  IUsuarioModif,
} from "@/entities";

export interface IClientexdel {
  active: boolean;
  delegacion: IDelegacion;
  cliente: ICliente;
  fecCrear: string;
  fecModif: string;
  id: number;
  numLimite: number;
  puntosservicio: IPuntoservicio[];
  usuarioCrear: IUsuarioCrear;
  usuarioModif: IUsuarioModif;
}

export const iniClientexdel: IClientexdel = {
  active: false,
  delegacion: iniDelegacion,
  cliente: iniCliente,
  fecCrear: "",
  fecModif: "",
  id: 0,
  numLimite: 0,
  puntosservicio: [],
  usuarioCrear: {} as IUsuarioCrear,
  usuarioModif: {} as IUsuarioModif,
};
