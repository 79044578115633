
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";
import { IVideoFilter } from "@/services";
import {
  ICliente,
  IClientexdel,
  IDelegacion,
  IParametro,
  IPuntoservicio,
  IUsuario,
} from "@/entities";

@Component({
  mixins: [VueMethods],
})
export class VideosFilterForm extends Vue {
  @Prop({ required: true }) value!: IVideoFilter;
  @Prop({ required: true }) optionEstados!: IParametro[];
  @Prop({ required: true }) optionTiposNovedad!: IParametro[];
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.$set(
      this.valueLocal,
      "fechaNovedad",
      this.valueLocal.fechaNovedad
        ? new Date(this.valueLocal.fechaNovedad)
        : this.getStartDate(new Date().toISOString())
    );
    this.$set(
      this.valueLocal,
      "fechaSubida",
      this.valueLocal.fechaSubida ? new Date(this.valueLocal.fechaSubida) : undefined
    );
  }
  validado = false;
  valueLocal: IVideoFilter = {
    ciudad: undefined,
    sucursal: undefined,
    fechaNovedad: this.getStartDate(new Date().toISOString()),
    fechaSubida: undefined,
    nombreRevisores: undefined,
    cedulasCiudadania: undefined,
    observaciones: undefined,
    nombreArchivo: undefined,
    estado: [],
    tipoNovedad: [],
    delegationsSelected: [],
    puntosSelected: [],
  };
  initialValueLocal: IVideoFilter = {
    ciudad: undefined,
    sucursal: undefined,
    fechaNovedad: this.getStartDate(new Date().toISOString()),
    fechaSubida: undefined,
    nombreRevisores: undefined,
    cedulasCiudadania: undefined,
    observaciones: undefined,
    nombreArchivo: undefined,
    estado: [],
    tipoNovedad: [],
    delegationsSelected: [],
    puntosSelected: [],
  };
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getLocale") locale!: Locale;
  @Getter("getLoggedUser") getLoggedUser!: IUsuario;
  @Getter("getClienteSelected") clienteSelected: ICliente | undefined;
  public delegations: IDelegacion[] = [];
  public puntos: IPuntoservicio[] = [];

  mounted(): void {
    this.getDelegaciones();
    this.getPuntos();
  }

  private cliente: ICliente | undefined = undefined;
  private static readonly SORTBY_DELEGATIONS: string = "desDelegacion";
  private static readonly SORTBY_PUNTOS: string = "desPuntoservicio";

  async getDelegaciones(): Promise<void> {
    this.cliente = this.clienteSelected;
    this.cliente?.clientexdels.forEach((clientexdel) => {
      if (clientexdel.active) this.delegations.push(clientexdel.delegacion);
    });
    this.delegations.sort((a, b) => this.sortAscending(a, b, VideosFilterForm.SORTBY_DELEGATIONS));
  }
  async getPuntos(): Promise<void> {
    if (this.cliente != undefined) {
      let cxds = this.cliente.clientexdels;
      if (this.getLoggedUser.nivel == 4) {
        const puntosPermitidos = this.getLoggedUser.permisos.map((punto) => punto.puntoservicio.id);
        cxds.forEach((clientexdel) => {
          clientexdel.puntosservicio = clientexdel.puntosservicio.filter((punto) =>
            puntosPermitidos.includes(punto.id)
          );
        });
      }
      if (this.valueLocal.delegationsSelected?.length) {
        cxds = cxds?.filter((cxd: IClientexdel) =>
          this.valueLocal.delegationsSelected.includes(cxd.delegacion?.id)
        );
      }
      cxds?.forEach((cxd) =>
        this.puntos.push(
          ...(cxd.puntosservicio.sort((a, b) =>
            this.sortAscending(a, b, VideosFilterForm.SORTBY_PUNTOS)
          ) || [])
        )
      );
    }
  }

  limpiar(): void {
    this.valueLocal.ciudad = undefined;
    this.valueLocal.sucursal = undefined;
    this.valueLocal.nombreRevisores = undefined;
    this.valueLocal.cedulasCiudadania = undefined;
    this.valueLocal.observaciones = undefined;
    this.valueLocal.nombreArchivo = undefined;
    this.valueLocal.estado = [];
    this.valueLocal.tipoNovedad = [];
    this.valueLocal.delegationsSelected = [];
    this.valueLocal.puntosSelected = [];
    this.$set(this.valueLocal, "fechaNovedad", this.getStartDate(new Date().toISOString()));
    this.$set(this.valueLocal, "fechaSubida", undefined);
    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }
}
export default VideosFilterForm;
