import { iniPais, IPais } from "@/entities";

export interface IDelegacion {
  clientexdels: any[];
  codDelegacionSol: string;
  codTimezone: string;
  desDelegacion: string;
  active: boolean;
  id: number;
  pais: IPais;
}
export const iniDelegacion: IDelegacion = {
  clientexdels: [],
  codDelegacionSol: "",
  codTimezone: "",
  desDelegacion: "",
  active: false,
  id: 0,
  pais: iniPais,
};
