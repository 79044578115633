import { render, staticRenderFns } from "./VideosPlayForm.vue?vue&type=template&id=23aa5f58&scoped=true"
import script from "./VideosPlayForm.vue?vue&type=script&lang=ts"
export * from "./VideosPlayForm.vue?vue&type=script&lang=ts"
import style0 from "./VideosPlayForm.vue?vue&type=style&index=0&id=23aa5f58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23aa5f58",
  null
  
)

export default component.exports